<template>
    <div @click="cseClick">
        <b-loading v-model="loading"></b-loading>
        <web-search-skeleton v-if="loading && searchType !== 'images'" />

        <!--
        <div v-if="searchType === 'images'">
          <b-field grouped>
              <b-select v-model="selectedImageSize" placeholder="Size">
                  <option
                      v-for="option in imageSizeOpts"
                      :value="option.value"
                      :key="option.value">
                      {{ option.label }}
                  </option>
              </b-select>
          </b-field>
        </div>
        -->

        <div class="cse-container" ref="cse-target"></div>
        
        <div class="container">
          <b-pagination
            class="pt-4"
            v-if="cseRendered"
            :total="totalItems"
            v-model="currentPage"
            range-before="1"
            range-after="4"
            :per-page="perPage"
            icon-prev="chevron-left"
            icon-next="chevron-right"
            @change="pageChanged"
            >
          </b-pagination>
        </div>

    </div>
</template>

<script>
import WebSearchSkeleton from '@/components/WebSearchSkeleton'

const CSE_URL = process.env.VUE_APP_CSE_URL;
const GNAME = 'g-search-res';

export default {
  name: "CseResults",
  
  data: () => ({
    loading: false,
    cseRendered: false,
    cseReady: false,
    currentPage: 1,
    perPage: 10,
    searchType: 'web',
    refinementIndex: 0,
    query: null,
    selectedImageSize: null,
    totalPages: 0,
    captchaMonitor: null,
    imageSizeOpts: [
      {label: 'Icon', value:'icon'},
      {label: 'Small', value:'small'},
      {label: 'Medium', value:'medium'},
      {label: 'Large', value:'large'},
      {label: 'XLarge', value:'xlarge'},
      {label: 'XXLarge', value:'xxlarge'},
      {label: 'HUGE', value:'huge'},
    ],
    imageTypeOpts: [
      {label: 'Clip Art', value:'clipart'},
      {label: 'Face', value: 'face'},
      {label: 'Line Drawings', value: 'lineart'},
      {label: 'Stock Photos', value: 'stock'},
      {label: 'Photos', value: 'photo'},
      {label: 'Animated', value: 'animated'}
    ],
    imageFileTypeOpts:[
      {label:'Jpeg', value:'jpg'},
      {label:'gif', value:'gif'},
      {label:'png', value:'png'},
      {label:'bmp', value:'bmp'},
      {label:'svg', value:'svg'},
      {label:'webp', value:'webp'},
      {label:'ico', value:'ico'},
      {label:'raw', value:'raw'},
    ]
  }),

  components: {
      WebSearchSkeleton
  },
  methods: {
    pageChanged(page){
      this.$eventBus.$emit('scrollToTop');
      document.querySelectorAll('.gsc-cursor')[this.refinementIndex].querySelectorAll('.gsc-cursor-page')[page-1].click();
    },
    clearCSE() {
      if (this.cseRendered) {
        google.search.cse.element.getElement(GNAME).clearAllResults();
        this.loading = false;
        this.cseRendered = false;
        ///this.totalItems = 0;
        this.currentPage = 1;
        clearInterval(this.captchaMonitor);
      }
    },
    renderCSE(query, searchType = null) {
        if(!this.cseReady){
            setTimeout(()=>{
                this.renderCSE(query, searchType)
            }, 250)
        }else{
            this.searchType = searchType;
            this.query = query;
            this.loading = true;
            this.clearCSE();
            try{
              google.search.cse.element.render({
                  div: this.$refs["cse-target"],
                  tag: "searchresults-only",
                  gname: GNAME,
                  attributes: {
                    autoSearchOnLoad: false,
                    mobileLayout: 'disabled',
                    defaultToImageSearch: searchType === "images",
                    adtest: process.env.VUE_APP_ENV_NAME === 'development' ? 'on' : 'off',
                    sort_by: searchType === "news" ? 'date' : null,
                    //imageSearchResultSetSize: this.selectedImageSize
                    //defaultToRefinement: searchType === 'news' ? process.env.VUE_APP_CSE_NEWS_REFINEMENT_ID : false
                    //imageSearchLayout: 'popup'
                  },
              });
              google.search.cse.element.getElement(GNAME).execute(query)
            }catch(err){
              this.loading = false;
              console.error(err);
            }
        }
    },

    renderCSECallback() {
        this.totalPages = document.querySelectorAll('.gsc-resultsbox-visible .gsc-cursor-page').length;
        this.loading = false;
        this.cseRendered = true;
        clearInterval(this.captchaMonitor);
    },

    startingCSECallback(){
      this.loading = true;
      clearInterval(this.captchaMonitor);
      this.captchaMonitor = setInterval(() => {
        const hasCaptcha = !!document.querySelector('.gs-captcha-msg');
        if(hasCaptcha){
          this.loading = false;
          clearInterval(this.captchaMonitor);
        }
      }, 1000)
    },

    cseClick(event){
      this.handleRefinementHeaderClick(event);
    },

    handleRefinementHeaderClick(event){
      const parentNode = event?.target?.parentNode;
      const isRefinementHeader = parentNode?.className?.includes('refinementHeader');
      if(isRefinementHeader){
        this.refinementIndex = this.getRefinementIndex(parentNode);
        this.currentPage = 1;
        this.resetCurrentRefinementPage();
      }
    },

    resetCurrentRefinementPage(){
      const cursor = document.querySelectorAll('.gsc-cursor')[this.refinementIndex];
      if(cursor){
        cursor.querySelectorAll('.gsc-cursor-page')[0].click();
      }
    },

    getRefinementIndex(refinementHeader){
      const refinements = document.querySelectorAll('.gsc-refinementHeader');
      if(refinements.length){
        return [].indexOf.call(refinements, refinementHeader);
      }
      return 0;
    },  

    initCSE() {
      window.__gcse = {
        parsetags: "explicit", // Defaults to 'onload'
        initializationCallback: () => {
          //this.renderCSE();
          this.cseReady = true;
        },
        searchCallbacks: {
          image: {
            starting: () => { 
              this.startingCSECallback();
            },
            //ready: myImageResultsReadyCallback,
            rendered: () => {
              this.renderCSECallback();
            },
          },
          web: {
            starting: () => {
              this.startingCSECallback();
            },
            //ready: myWebResultsReadyCallback,
            rendered: () => {
              this.renderCSECallback();
            },
          },
        },
      };

      try {
        this.$loadScript(CSE_URL);
      } catch (err) {
        console.error(err);
      }
    },
  },

  computed:{
    
    totalItems(){
      return this.totalPages * this.perPage;
    }
    
  },

  watch:{
    selectedImageSize(){
      this.renderCSE(this.query, this.searchType);
    }
  },

  mounted() {
    this.initCSE();
  },
};
</script>

<style lang="scss" scoped>
.cse-container{
    min-height: 800px;
}
</style>